module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","name":"AYS Jewels | Premium Antique Galleria | Kondotty","short_name":"AYS Jewels | Premium Antique Galleria | Kondotty","description":"Discover the beauty and elegance of antique jewelry at AYS Jewels Kondotty, a premium shop where you can find unique and timeless pieces to add to your collection.","lang":"en","display":"standalone","start_url":"/","background_color":"#06231A","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"41bc491196ac49d47d171d4215032de5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
